import React, {useMemo, useState} from 'react';
import FilterComponent from "./FilterComponent";
import DataTable from "react-data-table-component";

const Table = ({columns, videos}) => {

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = videos.filter(item =>
    (item.song && item.song.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.singer && item.singer.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.author && item.author.toLowerCase().includes(filterText.toLowerCase()))
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <DataTable
        title="Liste des chansons"
        columns={columns}
        data={filteredItems}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        pagination={true}
        paginationPerPage={10}
        paginationComponentOptions={{
          noRowsPerPage: true
        }}
        highlightOnHover={true}
        customStyles={{
          header: {
            style: { fontWeight: "bold", color: "red", fontSize: "36px" }
          },
          rows: {
            style: { cursor: "pointer" }
          },
          headCells: {
            style: { fontWeight: "bold", fontSize: "16px" }
          },
          cells: {
            style: { fontSize: "14px" }
          },
          pagination: {
            style: { fontWeight: "bold", fontSize: "14px" }
          }
        }}
      />
    </>
  );
}

export default Table;