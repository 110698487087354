import { FaPlayCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

export const columns = [
  {
    width: "50px",
    cell: ((video) => {
        return(
          <Link
            to={`/video/${video.slug}`}
          >
            <FaPlayCircle
              style={{
              fontSize: "25px",
              color: "red"
            }}
            title={`Jouer ${video.song} interprété par ${video.singer}`}
            />
          </Link>
        )
      }
    )
  },
  {
    name: "Photo",
    width: "100px",
    cell: ((video) => {
      return(
        <img
          src={`../thumbnails/${video.thumbnail}`}
          width = "75"
          alt={video.singer}
          style={ video.thumbnail && {borderRadius: "50%", border: "2px solid #333"}}/>
      )
    })
  },
  {
    name: "Chanson",
    selector: (row) => row.song,
    sortable: true
  },
  {
    name: "Chanteur",
    selector: (row) => row.singer,
    sortable: true
  },
  {
    name: "Catégorie",
    width: "10%",
    selector: (row) => row.category,
    sortable: true
  },

  {
    name: "Auteur",
    selector: (row) => row.author,
    sortable: true
  }
];