import React from 'react';

const FilterComponent = ({ filterText, onFilter, onClear }) => (

      <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="search"
          placeholder="Rechercher..."
          aria-label="Search Input"
          // size="25"
          value={filterText}
          autoComplete="off"
          onChange={onFilter}
        />
        <button
          className="btn btn-sm btn-warning"
          type="button"
          id="button-addon2"
          onClick={onClear}
        >
          X
        </button>
      </div>

);

export default FilterComponent;