import React, { useContext, useEffect, useRef } from "react";
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
} from "react-share";
import data from "../../data/data.json";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ShareDataContext } from "../../context/ShareDataContext";
import Nft from "../Nft/Nft";


const Player = () => {

  const { getVideoToPlay, videoToPlay } = useContext(ShareDataContext);

  const videoRef = useRef();
  const slug = useParams();

  useEffect(() => {
    window.scrollTo(0,0);
    getVideoToPlay(data.videos, slug.videoId)
    setTimeout(() => {
      videoRef.current?.load();
    },500);
  },[getVideoToPlay, slug.videoId]);

  return (
    <>

      <Helmet>
        <title>{`${videoToPlay.song} - ${videoToPlay.singer}`}</title>
        <meta name="description" content={`${videoToPlay.descSeo}`} />
        <meta name="twitter:title" property="og:title" itemProp="name"
              content={`${videoToPlay.song} - ${videoToPlay.singer}`}/>
      </Helmet>

      <div className="mb-5" id="player">
        
        <div className="card-wrapper">
        <Nft />
          <div className="card">
            <video className="mb-5" controls type="video/mp4" poster={`../thumbnails/${videoToPlay.thumbnail}`} ref={videoRef}>
              <source
                src={`../${videoToPlay.video}`}
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
            <div className="container">
              <h4>{videoToPlay.song}</h4>
              <hr />
              <p>Catégorie : {videoToPlay.category}</p>
              <p>Interprète(s) : {videoToPlay.singer}</p>
              <p>Véritable(s) interprète(s) : {videoToPlay.author}</p>

              <hr className="dashed my-5"/>
              
              <div
      dangerouslySetInnerHTML={{__html: videoToPlay.desc}} 
    />
              
            </div>

          </div>
          <hr className="dashed"/>

          <div className="social-share mt-5">
            <h3>J'adore cette vidéo et je la partage</h3>
            <hr className="dashed"/>
            <p className="mt-5">
              <FacebookShareButton
                url={`https://fun-fake-parody.fr/video/${videoToPlay.slug}`}
                description={"Parodie de la chanson " + videoToPlay.song + " de " + videoToPlay.author + " chantée par " + videoToPlay.singer}
                quote={"Parodie de la chanson " + videoToPlay.song + " de " + videoToPlay.author + " chantée par " + videoToPlay.singer}
              >
                <FacebookIcon
                  size={50}
                  round
                />
              </FacebookShareButton>
              <TwitterShareButton
                url={`https://fun-fake-parody.fr/video/${videoToPlay.slug}`}
                title={"Parodie de la chanson " + videoToPlay.song + " de " + videoToPlay.author + " chantée par " + videoToPlay.singer}
              >
                <TwitterIcon
                  size={50}
                  round
                />
              </TwitterShareButton>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Player;