import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ShareDataContextProvider from "./context/ShareDataContext";
import Player from "./components/Player/Player";
import Categories from "./pages/Categories/Categories";

function App() {

  return (
    <BrowserRouter>
      <div id="App">
        <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} />
        <Header />
        <ShareDataContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/categorie/:catName" element={<Categories />} />
            <Route path="/video/:videoId" element={<Player />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </ShareDataContextProvider>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
