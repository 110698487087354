import { createContext, useCallback, useState } from "react";
import data from "../data/data.json";

export const ShareDataContext = createContext(null);

const ShareDataContextProvider = (props) => {

  const [allVideos, setAllVideos] = useState([]);
  const [fourVideos, setFourVideos] = useState([]);
  const [videoToPlay, setVideoToPlay] = useState({});

  const getAllVideos = useCallback(() => {
    setAllVideos(data.videos);
  },[]);

  const getVideosByCategory = useCallback((category) => {
    for (let i = 0; i < data.videos.length; i++) {
      if (data.videos[i].category.toLowerCase() === category) {
        setAllVideos(allVideos => [...allVideos, data.videos[i]]);
      }
    }
  },[]);

  const getLastFour = useCallback(() => {
    setFourVideos([]);
    for (let i = 0; i < 4; i++) {
      setFourVideos(fourVideos => [...fourVideos, data.videos[i]]);
    }
  },[]);

  const getVideoToPlay = useCallback((videos, slug) => {
    for (let i = 0; i < videos.length; i++){
      if (videos[i].slug === slug){
        setVideoToPlay(videos[i]);
      }
    }
  },[]);

  /////////////////////////////
  return (
    <ShareDataContext.Provider
      value={{
        getAllVideos,
        getVideosByCategory,
        getLastFour,
        getVideoToPlay,
        setAllVideos,
        setVideoToPlay,
        allVideos,
        fourVideos,
        videoToPlay,
      }}
    >
      {props.children}
    </ShareDataContext.Provider>
  )
}

export default ShareDataContextProvider;