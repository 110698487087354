import React from "react";

const Footer = () => {
  return (

    <footer className="mt-5" id="footer">
      <div className="container text-center">
        <p>© 2022 - {new Date().getFullYear()} - Fun Fake Parody</p>
      </div>
    </footer>

  );
};

export default Footer;