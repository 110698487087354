import React from "react";

const Nft = () => {
  return (
    <div style={{ textAlign: "center", marginBottom: "10px" }}>
      <script src="//pubdirecte.com/script/banniere.php?said=133045"></script>
    </div>
  );
};

export default Nft;
