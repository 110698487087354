import React, { useContext, useEffect } from 'react';
import { useMatch } from "react-router-dom";
import { ShareDataContext } from "../../context/ShareDataContext";
import TableComponent from "../../components/Table/TableComponent";
import { Helmet } from "react-helmet";

const Categories = () => {

  const { getVideosByCategory, allVideos, setAllVideos } = useContext(ShareDataContext);

  const routeMatch = useMatch("/categorie/:catName");

  const category = routeMatch.params.catName;

  useEffect(() => {
    setAllVideos([]);
    getVideosByCategory(category);
    window.scrollTo(0,0);
  },[routeMatch.params.catName, category, getVideosByCategory, setAllVideos]);

  return (
    <>
      <Helmet>
        <title>{`Fun Fake Parody - Catégorie ${category}`}</title>
        <meta name="description" content={`Voici toutes les chansons de la catégorie ${category} disponibles sur Fun Fake Parody`} />
      </Helmet>

      <div className="mb-5">
        <div className="card-wrapper">
          <h2>Catégorie : {category.charAt(0).toUpperCase() + category.slice(1)}</h2>
          <hr className="my-5" />
          <div className="my-5">
            <TableComponent allVideos={allVideos} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;