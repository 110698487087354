import React from 'react';
import { Link } from "react-router-dom";
import Nft from '../../../../components/Nft/Nft';

const LastFour = ({fourVideos}) => {

  return (
    <div id="last-four">
      <div className="last-four-container">
        <Nft />
        <h2>Les 4 dernières vidéos</h2>
        <hr/>
        <div className="card-last-four-container mt-5">
          {
            fourVideos.map(thumbnail =>
              <div className="card-last-four" key={thumbnail.id}>
                <img src={`../thumbnails/${thumbnail.thumbnail}`} alt={thumbnail.thumbnail} />
                <Link
                  to={`/video/${thumbnail.slug}`}
                  title={`Jouer ${thumbnail.song} interprétée par ${thumbnail.singer}`}
                >
                  <div className="card-text">JOUER</div>
                </Link>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default LastFour;