import React, { useContext, useEffect, useState } from 'react';
import TableComponent from "../../components/Table/TableComponent";
import data from "../../data/data.json";
import { ShareDataContext } from "../../context/ShareDataContext";
import { Helmet } from "react-helmet";
import LastFour from "./components/LastFour/LastFour";


const Home = () => {

  const { getAllVideos, allVideos, getLastFour, fourVideos } = useContext(ShareDataContext);

  const [count, setCount] = useState(0);

  useEffect(() => {
    getLastFour();
    getAllVideos();
    setCount(data.videos.length);
    window.scrollTo(0, 0);
  }, [getAllVideos, count, getLastFour]);

  return (
    <>

      <Helmet>
        <title>Fun Fake Parody - Accueil</title>
        <meta name="description" content="Bienvenue sur Fun Fake Parody, l'endroit où les chansons sont détournées avec un twist comique ! Grâce à notre technologie de deepfake avancée, nous créons des parodies de chansons qui vous feront hurler de rire. Écoutez des artistes célèbres chanter des paroles absurdes, ou des classiques revisités avec un twist décalé. Que vous soyez amateur de musique, de parodies ou simplement en quête de distraction, notre site vous offre un divertissement inégalé. Alors mettez vos écouteurs et préparez-vous à vous amuser avec Fun Fake Parody !" />
      </Helmet>

      <div className="mb-5" id="home">
        
        <LastFour fourVideos={fourVideos} />
  
        <div className="card-wrapper">
          <p className="my-5">Bienvenue sur Fun Fake Parody, l'endroit où les chansons sont détournées avec un twist comique ! Grâce à notre technologie de deepfake avancée, nous créons des parodies de chansons qui vous feront hurler de rire. Écoutez des artistes célèbres chanter des paroles absurdes, ou des classiques revisités avec un twist décalé. Que vous soyez amateur de musique, de parodies ou simplement en quête de distraction, notre site vous offre un divertissement inégalé. Alors mettez vos écouteurs et préparez-vous à vous amuser avec Fun Fake Parody !</p>
          <p><small>Avertissement pour le site web "Fun Fake Parody" :</small></p>

          <p><small>Ce site web est un site de parodie et de deepfake destiné à des fins de divertissement uniquement. Les images, vidéos et autres contenus présentés sur ce site ne sont pas destinés à être pris au sérieux et ne reflètent pas la réalité.</small></p>

          <p><small>Toutes les images et vidéos de ce site ont été créées à des fins de parodie et de satire, et sont utilisées dans le respect des droits d'auteur. Si vous êtes le propriétaire des droits d'auteur d'une image ou d'une vidéo utilisée sur ce site et que vous souhaitez que nous l'enlevions, veuillez nous contacter à l'adresse e-mail indiquée sur le site.</small></p>

          <p><small>Nous déclinons toute responsabilité pour tout préjudice ou dommage causé par l'utilisation de ce site ou par les contenus présentés sur celui-ci. Nous vous encourageons à utiliser ce site avec prudence et à ne pas prendre les contenus présentés ici au sérieux.</small></p>

          <p className="mb-5"><small>En utilisant ce site, vous acceptez de ne pas tenir responsable le site "Fun Fake Parody" pour tout préjudice ou dommage causé par son utilisation.</small></p>
          <hr />

          <h2 className="mt-5">Il y a actuellement {count} vidéos à visionner sur FUN FAKE PARODY !</h2>
          <hr className="my-5" />
          <div className="my-5">
            <TableComponent allVideos={allVideos} />
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;