import React from 'react';
import Table from "./Table";
import {columns} from "./datatable";

const TableComponent = (props) => {

  return (
    <article className="row mt-5 justify-content-center">
      <div className="col">
        <Table columns={columns} videos={props.allVideos} />
      </div>
    </article>
  );
};

export default TableComponent;