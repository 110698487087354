import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Link, NavLink } from "react-router-dom";

const Sidebar = (props) => {

  const [ menuOpen, setMenuOpen ] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  }

  const closeMenu = () => {
    setMenuOpen(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (
    <>

      <Menu {...props} id="sidebar" isOpen={menuOpen} onStateChange={(state) => handleStateChange(state)}>

        <Link className="title" to="/" onClick={() => closeMenu()}>FUN FAKE PARODY</Link>

        <hr className="dashed" />

        <NavLink className="menu-item" to="/" onClick={() => closeMenu()}>
          Accueil
        </NavLink>

        <NavLink className="menu-item" to="/categorie/francais" onClick={() => closeMenu()}>
          Français
        </NavLink>

        <NavLink className="menu-item" to="/categorie/latino" onClick={() => closeMenu()}>
          Latino
        </NavLink>

        <NavLink className="menu-item" to="/categorie/art" onClick={() => closeMenu()}>
          Art
        </NavLink>

        <NavLink className="menu-item" to="/categorie/allemand" onClick={() => closeMenu()}>
          Allemand
        </NavLink>

        <NavLink className="menu-item" to="/categorie/anglais-us" onClick={() => closeMenu()}>
          Anglais / US
        </NavLink>

        <NavLink className="menu-item" to="/categorie/gaming" onClick={() => closeMenu()}>
          Gaming
        </NavLink>



      </Menu>
    </>
  );
};

export default Sidebar;