import React, { useEffect, useRef } from 'react';

const Header = () => {

  const header = useRef();
  const slogan = useRef();

  function scrollHeader() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      header.current.style.fontSize = "30px";
      header.current.style.transition = "0.2s";
      slogan.current.style.fontSize = "30px";
      slogan.current.style.opacity = "0";
      slogan.current.style.transition = "0.2s";
    } else {
      header.current.style.fontSize = "90px";
      slogan.current.style.display = "block";
      slogan.current.style.opacity = "1";
      slogan.current.style.transition = "0.2s";
    }
  }

  useEffect(() => {
    window.onscroll = function() {
      scrollHeader();
    }
  }, []);

  return (
    <div className="shadow" id="header">
      <div ref={header}>FUN FAKE PARODY</div>
      <div className="slogan" ref={slogan}>site parodique humoristique</div>
    </div>
  );
};

export default Header;